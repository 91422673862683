import { Box, Button, Heading, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import GImage from '../DefinitionComponents/Utility/GImage';
import FadeInAnimation from '../DefinitionComponents/Animation-Components/FadeInAnimation';
import RouterLink from '../DefinitionComponents/Utility/Routing/RouterLink';
import React from 'react';

export const PortfolioSliderItem = ({ btnVariant, params }) => {
  return (
    <Flex
      flexDirection={'column'}
      as={motion.div}
      whileTap={{ cursor: 'grabbing' }}
      animate={{ scale: 0.2, opacity: 1, y: 0, x: 0 }}
      whileInView={{
        scale: 1,
        transition: { type: 'spring', duration: 2 },
      }}
      whileHover={{
        scale: 1.02,
        backgroundColor: '#333',
        cursor: 'grabbing',
        transition: { type: 'spring', duration: 1 },
      }}
      bg="brand.darkgray"
      w={{ base: '75vw', md: '35vw', lg: '350px ', xl: '350px' }}
      mx={4}
      p={{ base: 3, lg: 12 }}
      rounded="xl">
      <Box
        px={{ base: 12, lg: 3 }}
        pt={1}
        as={motion.div}
        style={{ pointerEvents: 'none' }}>
        <GImage src={params.image} />
      </Box>
      <Flex
        flexGrow={1}
        flexDirection={'column'}
        justifyContent={'space-between'}>
        <Box>
          <FadeInAnimation
            duration={0.5}
            delay={0}
            initialY={20}
            threshold={0.2}>
            <Heading
              size={{ base: 'lg', md: 'lg' }}
              textAlign={{ base: 'center', lg: 'left' }}
              fontWeight="bold"
              color="brand.lightgray">
              {params.title}
            </Heading>
          </FadeInAnimation>
          <FadeInAnimation
            w={'100%'}
            duration={0.5}
            delay={0.55}
            initialY={20}
            threshold={0.4}>
            <Heading
              textAlign={{ base: 'center', lg: 'left' }}
              size={{ base: 'md', md: 'md' }}
              mt={4}
              fontWeight="normal"
              color="brand.medgray">
              {params.headline1}
            </Heading>
          </FadeInAnimation>
        </Box>

        <RouterLink
          link={params.path}
          alignSelf={'flex-end'}>
          <Button
            mt={4}
            mx={{ base: 'auto', lg: 0 }}
            variant={btnVariant}
            bg={'brand.green'}
            color={'brand.blue'}>
            {params.title}
          </Button>
        </RouterLink>
      </Flex>
    </Flex>
  );
};
