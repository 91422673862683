import { Flex, Text, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';

export function CountPageDot({
  index,
  activeCurrentSlide,

  ...restProps
}) {
  return (
    <Flex
      _hover={{
        borderColor: 'brand.green',
      }}
      {...restProps}
      borderBottom={'2px solid'}
      borderColor={
        activeCurrentSlide == index ? 'brand.green' : 'brand.meddarkgray'
      }
      flexDirection="column"
      as={motion.div}
      sx={{ cursor: 'pointer' }}
      whileHover={{ scale: 1.2 }}>
      <Text
        pointerEvents={'none'}
        px={4}
        pb={2}
        textAlign={'center'}
        fontSize={'xs'}
        color={activeCurrentSlide == index ? 'brand.green' : 'brand.medgray'}>
        {index + 1}
      </Text>
      <Flex
        variant="pageButton"
        as={motion.div}
        justifyContent={'center'}
        alignItems={'center'}
        w={'100%'}
        h={'2px'}
        borderRadius={'100px'}></Flex>
    </Flex>
  );
}
