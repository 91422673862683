import {
  Box,
  Grid,
  GridItem,

} from "@chakra-ui/react";
import React from "react";
import GVideo from "../DefinitionComponents/Utility/GVideo";
import GImage from "../DefinitionComponents/Utility/GImage"
export default function Heroblock({
  children,
  bgColor,
  mediaOpacity,
  video,
  minH,
  justifyContent,
  mediaSrc,
  maxH
}) {
  return (
    <Grid templateColumns="repeat(1, 1fr)" maxH={maxH} overflow="hidden">
      <GridItem
        background={bgColor}
        gridRow="1"
        gridColumn="1" maxH={maxH} minH={minH}
        zIndex="1"
        overflow={"hidden"}
      >
        <Box h='100%'>
          {video ? (
            <GVideo
              src={mediaSrc}
              style={{
                minHeight: minH,
                width: 100 + "%",
                maxHeight: maxH,
                objectFit: "cover",
                opacity: mediaOpacity
              }}
           / >
              
          ) : (
            <GImage
        
              minH={minH}
              width='100%'
              height='100%'
              objectFit='cover'
              maxHeight={maxH}
              src={mediaSrc}
              opacity={mediaOpacity}
            />
          )}
        </Box>
      </GridItem>
      <GridItem gridRow="1" gridColumn="1" zIndex="2" display='flex' flexDirection={'column'} justifyContent={justifyContent}>
        {children}
      </GridItem>
    </Grid>
  );
}
