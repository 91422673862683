import React from 'react'
import {Container, Box,Button, Text} from "@chakra-ui/react"
import ImgTextModule from './ImgTextModule'
import RouterLink from '../DefinitionComponents/Utility/Routing/RouterLink'
import FadeInAnimation from '../DefinitionComponents/Animation-Components/FadeInAnimation'

function SixPointsBanner() {
  return (
    <Container
    variant="layoutContainer"
    
    mb={0.5}
    display="grid"
    gridGap={0.5}
    gridTemplateColumns={{
    
      sm: "repeat(1, 1fr)",
    }}
  >
   
    <ImgTextModule
      minH={{base:'70vh',lg:"750px"}}
      w="100%"
      src={"bbg2-1280web.mp4"}
      delay={0.9}
      duration={1}
      threshold={0.4}
      initialY={0}
      initialX={0}
      video={true}
      mediaOpacity={0.9}
      bgColor="black"
    >
      <FadeInAnimation threshold={0.4} duration={1}>
        <Box p={8}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text color="brand.white" fontSize="5xl">
             6 points of truth.
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text color="brand.green" fontSize="2xl">
             How we deal with the challenges of blockchain based investments.
            </Text>
          </FadeInAnimation>
    
          <RouterLink  isExternal={false} link={"/investors#sixCore"}>
          <Button
            mt={4}
            variant="solid"
            bg="brand.green"
            color="brand.darkgray"
          >
            Our Investment Strategy
          </Button>
          </RouterLink>
        </Box>
      </FadeInAnimation>
    </ImgTextModule>
  </Container>
  )
}

export default SixPointsBanner