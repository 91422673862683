import { Box, Container } from "@chakra-ui/react";
import React from "react";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import GImage from "../DefinitionComponents/Utility/GImage";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";
const links = [
  {
    id: 1,
    text: "Spacemesh",
    url: "/cryptos/spacemesh",
    imgurl: "targets/tct-spacemesh.png",
  },
  {
    id: 2,
    text: "XRP",
    url: "/cryptos/xrp",
    imgurl: "targets/tct-xrp.png",
  },
  {
    id: 3,
    text: "XLM",
    url: "/cryptos/xlm",
    imgurl: "targets/tct-stellar.png",
  },
];
function PortfolioGridWhiteCryptos() {
  return (
    <InViewTrigger threshold={0.4} triggerOnce={true}>
      {(inView) => (
        <Container
          variant="layoutContainer"
          mt={0.5}
          mb={0.5}
          p={0}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gridGap={0.5}
          gridTemplateColumns={{
            lg: "repeat(6, 1fr)",
            md: "repeat(4, 1fr)",
            sm: "repeat(3, 1fr)",
            base: "repeat(3,1fr)",
          }}
        >
          {links.map((link) => (
            <Box maxW={{ base: "45%", lg: "20%" }}>
              <FadeInAnimation
                threshold={0.4}
                delay={link.id * 0.1}
                duration={0.7}
                initialX={0}
                h="100%"
                initialY={10}
                whileHover={{ scale: 1.2 }}
              >
                <RouterLink
                  link={link.url}
                  key={link.id}
                  display="block"
                  minH={{ base: "20vh", lg: "200px" }}
                  w="100%"
                  p={{ base: 0, lg: 8 }}
                  isExternal={false}
                >
                  <GImage src={link.imgurl} />
                </RouterLink>
              </FadeInAnimation>
            </Box>
          ))}
        </Container>
      )}
    </InViewTrigger>
  );
}

export default PortfolioGridWhiteCryptos;
