import React from 'react';
import { motion } from 'framer-motion';
import { Box, GridItem, Img } from '@chakra-ui/react';
import FadeInAnimation from '../DefinitionComponents/Animation-Components/FadeInAnimation';
import GImage from '../DefinitionComponents/Utility/GImage';
import GVideo from '../DefinitionComponents/Utility/GVideo';
const MotionBox = motion(Box);
export default function ImgTextModule({
    src,
    delay,
    duration,
    children,
    threshold,
    initialY,
    initialX,
    video,
    mediaOpacity,
    bgColor,
    objectPosition,
    ...restProps
}) {
    return (
        <Box {...restProps}>
            <Box
                as='section'
                w={'100%'}
                h={'100%'}
                display={'grid'}
                background={bgColor}>
                <GridItem
                    gridRow='1'
                    gridColumn='1'
                    zIndex='1'
                    position={'relative'}
                    overflow={'hidden'}>
                    <FadeInAnimation
                        duration={duration}
                        overflow={'hidden'}
                        delay={delay}
                        threshold={threshold}
                        initialY={initialY}
                        initialX={initialX}
                        style={{ height: 100 + '%' }}>
                        {video ? (
                            <GVideo
                                src={src}
                                style={{
                                    width: 100 + '%',
                                    height: 100 + '%',
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    opacity: mediaOpacity,
                                }}
                            />
                        ) : (
                            <GImage
                                src={src}
                                alt={' '}
                                w={'100%'}
                                h={'100%'}
                                objectFit={'cover'}
                                objectPosition={objectPosition}
                                position={'absolute'}
                                top={0}
                                left={0}
                                opacity={mediaOpacity}
                            />
                        )}
                    </FadeInAnimation>
                </GridItem>

                <GridItem
                    gridRow='1'
                    gridColumn='1'
                    zIndex='2'
                    h={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'end'}>
                    <Box>{children}</Box>
                </GridItem>
            </Box>
        </Box>
    );
}
